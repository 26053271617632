<template>
  <div class="component-wrapper">
    <div class="text-h5 font-weight-medium">Invoices</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>