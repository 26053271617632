<template>
  <v-footer
    app
    absolute
    class="d-flex flex-column align-center pb-1 pt-2 footer"
    color="white"
  >
    <div class="d-flex align-center justify-center mb-2">
      <div class="caption mr-8">Need Help?</div>

      <div class="caption d-flex align-center mr-8">
        <v-icon color="black" small class="mr-2">mdi-phone</v-icon>
        <div>Call Support: +30 212 1042659</div>
      </div>

      <div class="caption d-flex align-center">
        <v-icon color="black" small class="mr-2">mdi-email-outline</v-icon>
        <div>Email: info@dn2me.com</div>
      </div>
    </div>

    <div class="caption primary--text">
      DoctorNext2Me © {{ year }}. All Rights Reserved.
    </div>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #eeeeee;
}
</style>