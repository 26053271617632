<template>
  <div>
    <div class="subtitle-2 font-weight-medium mb-4">
      {{ role == "ta_finance" ? "Finance Managers" : "Operators" }} ({{
        subusers.length
      }})
    </div>

    <v-row class="grey lighten-3" no-gutters>
      <v-col class="pa-4">
        <div class="subtitle-1">First Name</div>
      </v-col>

      <v-col class="pa-4">
        <div class="subtitle-1">Last Name</div>
      </v-col>

      <v-col class="pa-4">
        <div class="subtitle-1">Phone Number</div>
      </v-col>

      <v-col class="pa-4">
        <div class="subtitle-1">Email</div>
      </v-col>

      <v-col class="pa-4 text-center">
        <div class="subtitle-1">Action</div>
      </v-col>
    </v-row>

    <div class="body-1 mt-8 mb-4 px-4" v-if="subusers.length == 0">
      Add your first
      {{ role == "ta_finance" ? "Finance Manager" : "Operator" }}
    </div>

    <div v-for="subuser in subusers" :key="subuser.userId">
      <v-row no-gutters>
        <v-col class="pa-4 d-flex align-center flex-grow-1 flex-shrink-1">
          <div class="subtitle-1">{{ subuser.firstName }}</div>
        </v-col>

        <v-col class="pa-4 d-flex align-center flex-grow-1 flex-shrink-1">
          <div class="subtitle-1">{{ subuser.lastName }}</div>
        </v-col>

        <v-col class="pa-4 d-flex align-center flex-grow-1 flex-shrink-1">
          <div class="subtitle-1">{{ subuser.phone }}</div>
        </v-col>

        <v-col class="pa-4 d-flex align-center flex-grow-1 flex-shrink-1">
          <div class="subtitle-1">{{ subuser.email }}</div>
        </v-col>

        <v-col
          class="
            pa-4
            d-flex
            align-center
            flex-grow-1 flex-shrink-1
            justify-center
          "
        >
          <v-btn
            text
            color="error"
            @click="onDeleteSubuser(subuser.userId)"
            :loading="loader.open && subuser.userId == loader.id"
            >remove</v-btn
          >
        </v-col>
      </v-row>

      <v-divider class="mx-4"></v-divider>
    </div>

    <NewSubUser v-if="addNew" @close="addNew = false" :role="role" />

    <v-btn
      color="primary"
      text
      class="mt-4"
      :disabled="addNew"
      @click="addNew = true"
    >
      <v-icon>mdi-plus</v-icon>
      Add New
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NewSubUser from "@/views/profile/company-structure/NewSubUser";

export default {
  components: {
    NewSubUser,
  },
  props: ["role"],
  data() {
    return {
      addNew: false,
      loader: {
        open: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapState({
      managers: (state) => state.profile.managers,
      operators: (state) => state.profile.operators,
    }),
    subusers() {
      if (this.role == "ta_finance") {
        return this.managers;
      } else {
        return this.operators;
      }
    },
  },
  methods: {
    ...mapActions(["deleteSubuser"]),
    async onDeleteSubuser(id) {
      this.loader = {
        open: true,
        id,
      };

      try {
        await this.deleteSubuser({
          userId: id,
          role: this.role,
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = {
        open: false,
        id: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  flex: 1 0 0 !important;
  min-width: 0 !important;
}
</style>