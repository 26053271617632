<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex align-center justify-space-between mb-2">
        <div class="body-2 font-weight-medium">
          This user is a validated member.
        </div>

        <div class="body-2">Pending incident approval.</div>
      </div>

      <div class="text-h6 font-weight-regular primary--text text-end">
        {{ timer }}
      </div>
    </div>

    <v-divider class="my-5"></v-divider>

    <div class="d-flex justify-end mb-2">
      <v-btn
        color="error"
        depressed
        rounded
        width="90px"
        class="mr-2"
        :loading="denyLoader"
        @click="onIncidentDeny"
        >Reject</v-btn
      >

      <v-btn
        color="success"
        depressed
        rounded
        width="90px"
        :loading="acceptLoader"
        @click="onIncidentAccept"
        >Approve</v-btn
      >
    </div>

    <v-divider class="my-5"></v-divider>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      denyLoader: false,
      acceptLoader: false,
      interval: null,
      remaining: null,
    };
  },
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
    timer() {
      if (this.remaining <= 0) return "00:00:00";

      let minutes = Math.floor(this.remaining / 60);
      let seconds = this.remaining % 60;
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;

      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  created() {
    const secondsUntilTimeout =
      this.activeIncident.secondsForTAClientAcceptance +
      this.activeIncident.secondsForTAIncidentAcceptance;

    const taNotifiedOn = moment.utc(this.activeIncident.taNotifiedOn).local();
    const now = moment.utc(new Date()).local();

    const diffSeconds = Math.floor(
      moment.duration(now.diff(taNotifiedOn)).asSeconds()
    );

    this.remaining = secondsUntilTimeout - diffSeconds;

    this.interval = setInterval(() => {
      if (this.remaining <= 0) {
        clearInterval(this.interval);
        return;
      }

      this.remaining--;
    }, 1000);
  },
  methods: {
    ...mapActions(["incidentResponse", "getIncidents"]),
    ...mapMutations(["setActiveIncident"]),
    async onIncidentDeny() {
      this.denyLoader = true;

      try {
        await this.incidentResponse(false);

        await this.getIncidents(true);
        this.setActiveIncident(this.activeIncident.emergencyId);
      } catch (e) {
        console.log(e);
      }

      this.denyLoader = false;
    },
    async onIncidentAccept() {
      this.acceptLoader = true;

      try {
        await this.incidentResponse(true);

        await this.getIncidents(true);
        this.setActiveIncident(this.activeIncident.emergencyId);
      } catch (e) {
        console.log(e);
      }

      this.acceptLoader = false;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
</style>