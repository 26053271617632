<template>
  <div class="component-wrapper">
    <div class="text-h5 font-weight-medium mb-8">Company Structure</div>
    <Subusers role="ta_finance" class="mb-8" />

    <Subusers role="ta_operation" />
  </div>
</template>

<script>
import Subusers from "@/views/profile/company-structure/Subusers";

export default {
  components: {
    Subusers,
  },
};
</script>

<style lang="scss" scoped>
</style>