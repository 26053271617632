<template>
  <v-card class="card">
    <div class="d-flex flex-column pa-2">
      <div class="px-4 text-h6 text-center">Complete Registration</div>
      <BillingInformation v-if="nextStep == 'billing'" />
      <CardInformation v-if="nextStep == 'card'" />
    </div>
  </v-card>
</template>

<script>
import BillingInformation from "@/views/profile/BillingInformation";
import CardInformation from "@/views/profile/CardInformation";
import { mapActions, mapState } from "vuex";
export default {
  async created() {
    try {
      await this.getCountries();
    } catch (e) {}
  },

  components: {
    BillingInformation,
    CardInformation,
  },

  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    ...mapState({
      nextStep: (state) => state.base.nextStep,
    }),
  },

  methods: {
    ...mapActions(["getCountries"]),
  },
};
</script>

<style lang="scss">
.v-dialog--fullscreen {
  background-color: white !important;
}

card {
  background-color: white !important;
  width: 100vw;
  height: 100vh;
}
</style>