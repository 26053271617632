<template>
  <div class="component-wrapper">
    <div class="d-flex align-center mb-5">
      <img
        class="profile-img mr-5"
        v-if="profile"
        :src="profile.userProfile.profilePic"
      />

      <div class="text-h5 font-weight-medium">My Profile</div>
    </div>

    <div>
      <v-tabs v-model="tab" slider-color="primary">
        <v-tab v-for="(tab, index) in tabs" :key="index" :to="tab.route">
          {{ tab.text }}
        </v-tab>
      </v-tabs>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      tabs: [
        {
          text: "General Information",
          route: "/profile/general",
        },
        {
          text: "Company Structure",
          route: "/profile/company",
        },
        {
          text: "Billing Information",
          route: "/profile/billing",
        },
        {
          text: "Card Information",
          route: "/profile/card",
        },
        {
          text: "Account Settings",
          route: "/profile/account",
        },
      ],
      tab: 1,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),
  },
  async created() {
    this.getCountries();
  },
  methods: {
    ...mapActions(["getProfile", "getCountries"]),
  },
};
</script>

<style lang="scss" scoped>
.profile-img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
</style>