<template>
  <div class="component-wrapper d-flex flex-column pa-5">
    <v-progress-linear
      indeterminate
      color="primary"
      class="mb-5"
      v-if="incidentsLoader"
    ></v-progress-linear>

    <template
      v-if="!incidentsLoader && incidents.length == 0 && !getIncidentsError"
    >
      <img src="@/assets/cactus.png" class="align-self-center mb-2 mt-12" />

      <div class="text-h6 font-weight-medium text-center mb-1">
        No incidents yet
      </div>

      <div class="body-1 text-center">Enjoy your day</div>
    </template>

    <template
      v-if="!incidentsLoader && incidents.length > 0 && !getIncidentsError"
    >
      <div
        class="text-h5 font-weight-medium text-center align-self-center mb-4"
      >
        Incidents
      </div>

      <div class="scrollable mb-4">
        <div
          class="incident-card"
          :class="{
            active:
              activeIncident &&
              activeIncident.emergencyId == incident.emergencyId,
          }"
          v-for="(incident, index) in incidents"
          :key="incident.emergencyId"
          @click="
            $router.push({
              path: `/incidents/${incident.emergencyId}/general-information`,
            })
          "
        >
          <div class="pa-4">
            <div class="d-flex align-start">
              <div class="photo-wrapper mr-4">
                <img :src="incident.ownerTraveller.userProfile.profilePic" />
              </div>

              <div class="d-flex flex-column">
                <div class="subtitle-1 font-weight-medium mb-1">
                  {{ incident.ownerTraveller.userProfile.firstName }}
                  {{ incident.ownerTraveller.userProfile.lastName }}
                </div>

                <div class="caption mb-1">
                  {{ incident.emergencyLocation.countryName }}
                </div>

                <div
                  class="caption primary--text font-weight-medium"
                  v-if="
                    incident.taState == 'ta_pending' ||
                    incident.taState == 'ta_clientapproved'
                  "
                >
                  Pending approval
                </div>

                <div
                  class="d-flex caption error--text font-weight-medium"
                  v-if="incident.taState == 'ta_timedout'"
                >
                  <div class="mr-2">Timed out:</div>
                  <div>
                    {{
                      moment
                        .utc(incident.taTimedOutOn)
                        .local()
                        .format("DD/MM/YY HH:mm")
                    }}
                  </div>
                </div>

                <div
                  class="d-flex caption error--text font-weight-medium"
                  v-if="incident.taState == 'ta_rejected'"
                >
                  <div class="mr-2">Rejected:</div>
                  <div>
                    {{
                      moment
                        .utc(incident.taRespondedOn)
                        .local()
                        .format("DD/MM/YY HH:mm")
                    }}
                  </div>
                </div>

                <div
                  class="d-flex caption success--text font-weight-medium"
                  v-if="incident.taState == 'ta_approved'"
                >
                  <div class="mr-2">Approved:</div>
                  <div>
                    {{
                      moment
                        .utc(incident.taRespondedOn)
                        .local()
                        .format("DD/MM/YY HH:mm")
                    }}
                  </div>
                </div>

                <div
                  class="caption error--text font-weight-medium"
                  v-if="incident.taState == 'ta_travcancelled'"
                >
                  Cancelled by patient
                </div>
              </div>

              <div class="caption incident-date text-center ml-auto">
                {{
                  moment
                    .utc(incident.taNotifiedOn)
                    .local()
                    .format("DD/MM/YY HH:mm")
                }}
              </div>
            </div>
          </div>

          <v-divider
            class="mt-2"
            v-if="index != incidents.length - 1"
          ></v-divider>
        </div>
      </div>
    </template>

    <v-pagination
      :value="currentPage"
      class="mt-auto"
      :total-visible="5"
      :length="numOfPages"
      v-if="
        (!incidentsLoader || numOfPages) &&
        incidents.length > 0 &&
        !getIncidentsError
      "
      @input="onChangePage"
    ></v-pagination>

    <v-alert text dense type="error" v-if="getIncidentsError">
      There was an error while fetching your incidents. Please, try again later.
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      moment,
    };
  },
  computed: {
    ...mapState({
      incidents: (state) => state.ta.incidents,
      currentPage: (state) => state.ta.currentPage,
      numOfPages: (state) => state.ta.numOfPages,
      activeIncident: (state) => state.ta.activeIncident,
      incidentsLoader: (state) => state.ta.incidentsLoader,
      getIncidentsError: (state) => state.ta.getIncidentsError,
    }),
  },
  async created() {
    try {
      await this.getIncidents();

      if (this.incidents.length > 0) {
        this.$router.push({
          path: `/incidents/${this.incidents[0].emergencyId}/general-information`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions(["getIncidents"]),
    ...mapMutations(["setCurrentPage", "setActiveIncident"]),
    async onChangePage(event) {
      this.setCurrentPage(event);

      try {
        await this.getIncidents(true);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.incident-card {
  cursor: pointer;
  border-radius: 2px;
  border-left: 3px solid transparent;

  &.active {
    background-color: #fff;
    border-left: 3px solid var(--v-primary-base);
  }
}

.photo-wrapper {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.incident-date {
  width: 50px;
  font-size: 10px !important;
}
</style>