<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-4 d-flex align-center">
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="First Name"
          v-model="firstName"
        ></v-text-field>
      </v-col>

      <v-col class="pa-4 d-flex align-center">
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="Last Name"
          v-model="lastName"
        ></v-text-field>
      </v-col>

      <v-col class="pa-4 d-flex align-center">
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="Phone Number"
          type="tel"
          v-model="phoneNumber"
        ></v-text-field>
      </v-col>

      <v-col class="pa-4 d-flex align-center">
        <v-text-field
          outlined
          dense
          hide-details
          placeholder="Email"
          type="email"
          v-model="email"
        ></v-text-field>
      </v-col>

      <v-col class="pa-4 d-flex align-center">
        <v-btn
          text
          color="primary"
          class="mr-2"
          :disabled="!firstName || !lastName || !phoneNumber || !email"
          @click="submit"
          :loading="loader"
          >Add</v-btn
        >

        <v-btn text color="error" @click="$emit('close')">Cancel</v-btn>
      </v-col>
    </v-row>

    <div class="body-2 font-weight-medium error--text px-4" v-if="formError">
      {{ formError }}
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";

const uniqueEmail = function () {
  return !this.emailExists;
};

export default {
  props: ["role"],
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      loader: false,
      emailExists: false,
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(12),
      integer,
    },
    email: { required, email, uniqueEmail },
  },
  computed: {
    formError() {
      if (!this.$v.$dirty) return null;

      if (
        !this.$v.phoneNumber.minLength ||
        !this.$v.phoneNumber.maxLength ||
        !this.$v.phoneNumber.integer
      )
        return "Please enter a valid mobile number";

      if (!this.$v.email.email) return "Please, enter a valid email format";

      if (!this.$v.email.uniqueEmail) return "This email already exists";

      return null;
    },
  },
  methods: {
    ...mapActions(["addSubUser"]),
    async submit() {
      this.emailExists = false;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;

      try {
        await this.addSubUser({
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
          role: this.role,
        });
        this.$emit("close");
      } catch (e) {
        if (e?.response?.data?.errorCode == 1002) {
          this.emailExists = true;
        } else {
          this.$emit("close");
        }
        console.log(e);
      }

      this.loader = false;
    },
  },
  watch: {
    email(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.emailExists = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>