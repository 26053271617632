<template>
  <div class="component-wrapper">
    <div class="text-h6">Billing Information</div>
    <div class="body-2 mb-5">Information for invoicing purpose</div>

    <form @submit.prevent="submit" novalidate>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            label="Bank name"
            class="mb-1"
            v-model="bankName"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="Bank full address"
            class="mb-1"
            v-model="bankAddress"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="Account Number"
            class="mb-1"
            v-model="iban"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="SWIFT/IBAN Reference"
            class="mb-1"
            v-model="ibanRef"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="Payment currency"
            class="mb-1"
            v-model="currency"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            outlined
            dense
            label="City"
            class="mb-1"
            v-model="city"
          ></v-text-field>

          <v-text-field
            outlined
            dense
            label="Postal Code"
            class="mb-1"
            v-model="postalCode"
          ></v-text-field>

          <v-autocomplete
            outlined
            dense
            label="Country"
            class="mb-1"
            v-model="country"
            :items="countries"
            item-text="option_value"
            item-value="option_key"
            :loading="countriesLoader"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        :disabled="
          $route.path.split('/')[1] != 'billing-info-register'
            ? !hasChanges
            : isEmpty
        "
        type="submit"
        :loading="loader"
      >
        <v-icon left v-if="$route.path.split('/')[1] != 'billing-info-register'"
          >mdi-content-save</v-icon
        >
        {{
          $route.path.split("/")[1] == "billing-info-register" ? "Next" : "Save"
        }}
      </v-btn>
    </form>

    <DiscardDialog :leavePath="leavePath" :initialize="initialize" />
  </div>
</template>

<script>
import DiscardDialog from "@/components/DiscardDialog";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {
    DiscardDialog,
  },
  data() {
    return {
      bankName: null,
      bankAddress: null,
      iban: null,
      ibanRef: null,
      currency: null,
      city: null,
      postalCode: null,
      country: null,
      initialData: null,
      leavePath: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
      countries: (state) => state.base.countries,
      countriesLoader: (state) => state.base.countriesLoader,
    }),
    isEmpty() {
      if (this.bankName == null) return true;
      if (this.bankAddress == null) return true;
      if (this.iban == null) return true;
      if (this.ibanRef == null) return true;
      if (this.currency == null) return true;
      if (this.city == null) return true;
      if (this.postalCode == null) return true;
      if (this.country == null) return true;

      return false;
    },

    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          bankName: this.bankName,
          bankAddress: this.bankAddress,
          ibanRef: this.ibanRef,
          iban: this.iban,
          currency: this.currency,
          city: this.city,
          postalCode: this.postalCode,
          country: this.country,
        })
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["toggleDiscardDialog", "setNextStep"]),
    ...mapActions(["saveBillingInfo"]),
    initialize() {
      this.bankName = this.profile?.billingProfile?.accountBankName;
      this.bankAddress =
        this.profile.billingProfile?.accountBankAddress?.address;
      this.ibanRef = this.profile?.billingProfile?.accountAccountWireNo;
      this.iban = this.profile?.billingProfile?.accountNo;
      this.currency = this.profile?.billingProfile?.accountCurrency;
      this.city = this.profile?.billingProfile?.accountBankAddress.city;
      this.postalCode =
        this.profile?.billingProfile?.accountBankAddress?.zipcode;
      this.country =
        this.profile?.billingProfile?.accountBankAddress?.countryCode;

      this.initialData = {
        bankName: this.bankName,
        bankAddress: this.bankAddress,
        ibanRef: this.ibanRef,
        iban: this.iban,
        currency: this.currency,
        city: this.city,
        postalCode: this.postalCode,
        country: this.country,
      };
    },
    async submit() {
      this.loader = true;

      try {
        await this.saveBillingInfo({
          billingInfo: {
            accountBankName: this.bankName,
            accountAccountWireNo: this.ibanRef,
            accountNo: this.iban,
            accountCurrency: this.currency,
            accountBankAddress: {
              address: this.bankAddress,
              city: this.city,
              zipcode: this.postalCode,
              countryCode: this.country,
            },
          },
          showSnack:
            this.$route.path.split("/")[1] == "billing-info-register"
              ? false
              : true,
        });

        if (this.$route.path.split("/")[1] == "billing-info-register")
          this.setNextStep("card");
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasChanges) {
      next();
    } else {
      this.leavePath = to.path;
      this.toggleDiscardDialog(true);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>