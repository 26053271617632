<template>
  <div class="d-flex flex-column pt-12">
    <v-btn
      link
      text
      class="d-flex align-center justify-start item mb-2 text-align-start"
      to="/dashboard"
      :disabled="true"
    >
      <v-icon color="primary" class="mr-2">mdi-chart-bar</v-icon>
      <span class="body-1">Dashboard</span>
    </v-btn>

    <v-btn
      link
      text
      class="d-flex align-center justify-start item mb-2 text-align-start"
      to="/incidents"
      :disabled="
        !(profile.companyProfile && profile.companyProfile.isProfileCompleted)
      "
    >
      <v-icon color="primary" class="mr-2">mdi-account</v-icon>
      <span class="body-1">Incidents</span>
    </v-btn>

    <v-btn
      link
      text
      class="d-flex align-center justify-start item mb-2 text-align-start"
      to="/invoices"
      :disabled="true"
    >
      <v-icon color="primary" class="mr-2">mdi-calendar-check</v-icon>
      <span class="body-1">Invoices</span>
    </v-btn>

    <v-divider class="my-5"></v-divider>

    <v-btn
      link
      text
      class="d-flex align-center justify-start item mb-2 text-align-start"
      to="/profile"
      :disabled="
        !(profile.companyProfile && profile.companyProfile.isProfileCompleted)
      "
    >
      <v-icon color="primary" class="mr-2">mdi-account</v-icon>
      <span class="body-1">My Profile</span>
    </v-btn>

    <v-btn
      link
      text
      class="d-flex align-center justify-start item mb-2 text-align-start"
      @click="dialog = true"
    >
      <v-icon color="primary" class="mr-2">mdi-logout</v-icon>
      <span class="body-1">Logout</span>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500px">
      <div class="white py-4 px-2">
        <div class="body-1 text-center mb-4">
          Are you sure you want to logout?
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            small
            class="mr-2"
            :loading="loader"
            @click="onLogout"
            >Logout</v-btn
          >

          <v-btn small @click="dialog = false">Cancel</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
      profile: (state) => state.profile.profile,
    }),
  },
  methods: {
    ...mapActions(["logout"]),

    async onLogout() {
      this.loader = true;

      await this.logout();

      this.loader = false;
      this.dialog = false;

      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
  padding: 12px 12px 12px 20px;

  &.blocked {
    cursor: not-allowed;
  }

  &.active {
    border-left: 5px solid var(--v-primary-base);
    background-color: #fafafa;
  }

  &:not(.active) {
    padding-left: 25px;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>