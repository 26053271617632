<template>
  <div class="component-wrapper d-flex flex-column justify-start align-center">
    <form
      class="form elevation-3 d-flex flex-column pa-8 pt-5 rounded"
      @submit.prevent="submit"
      novalidate
    >
      <div class="text-h6 text-center mb-4">Travel Assistant Login</div>

      <v-text-field
        outlined
        label="Email"
        type="email"
        prepend-inner-icon="mdi-account"
        v-model="email"
        :error="wrongCredentials"
      ></v-text-field>

      <v-text-field
        outlined
        label="Password"
        type="password"
        prepend-inner-icon="mdi-lock"
        v-model="password"
        :error-messages="
          wrongCredentials ? 'Incorrect username or password.' : ''
        "
      ></v-text-field>

      <div
        class="caption ml-auto mb-2 link"
        @click="$router.push({ path: '/forgot' })"
      >
        Did you forget your password?
      </div>

      <v-btn
        color="primary"
        type="submit"
        :loading="loader"
        :disabled="!email || !password"
        class="mb-5"
        >Login</v-btn
      >

      <div class="d-flex align-center ml-auto">
        <span class="caption mr-1"> Don't have an account? </span>

        <div class="caption link" @click="$router.push({ path: '/register' })">
          Register
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      loader: false,
      wrongCredentials: false,
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),
  },

  methods: {
    ...mapActions(["login", "getProfile"]),
    async submit() {
      this.wrongCredentials = false;
      this.loader = true;

      try {
        await this.login({
          username: this.email,
          password: this.password,
        });

        await this.getProfile(true);
        if (this.profile.companyProfile.isProfileCompleted)
          this.$router.push({ path: "/incidents" });
        else this.$router.push({ path: "/billing-info-register" });
      } catch (e) {
        if (
          e?.response?.data.errorCode == 10413 ||
          e?.response?.data.errorCode == 10414 ||
          e?.response?.data.errorCode == 10415
        ) {
          this.wrongCredentials = true;
        } else {
          console.log(e);
        }
      }

      this.loader = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 50px;
}
</style>