import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import BillingInformationRegister from '@/views/BillingInformation.vue'

import Dashboard from '../views/Dashboard.vue'

import Incidents from '@/views/incidents/Incidents.vue'
import IncidentSplash from '@/views/incidents/IncidentSplash.vue'
import IncidentDetails from '@/views/incidents/incident-details/IncidentDetails.vue'
import IncidentInformation from '@/views/incidents/incident-details/IncidentInformation.vue'
import MedicalReport from '@/views/incidents/incident-details/MedicalReport.vue'

import Invoices from '@/views/Invoices.vue'

import Profile from '@/views/profile/Profile.vue'
import GeneralInformation from '@/views/profile/GeneralInformation.vue'
import CompanyStructure from '@/views/profile/company-structure/CompanyStructure.vue'
import BillingInformation from '@/views/profile/BillingInformation.vue'
import CardInformation from '@/views/profile/CardInformation.vue'
import AccountSettings from '@/views/profile/AccountSettings.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    redirect: to => {
      if (store.getters.isLoggedIn) {
        return '/incidents'
      }

      return '/login'
    }
  },
  {
    path: '/billing-info-register',
    component: BillingInformationRegister,
    beforeEnter: ifAuthenticated,
  },


  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/incidents',
    component: Incidents,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        component: IncidentSplash
      },
      {
        path: ':id',
        component: IncidentDetails,
        beforeEnter(to, from, next) {
          store.commit("setActiveIncident", to.params.id);
          console.log(to)
          if (store.state.ta.activeIncident) {
            next()
          } else {
            next("/incidents")
          }

        },
        children: [
          {
            path: "general-information", //selected tab by default
            component: IncidentInformation
          },
          {
            path: "medical-report",
            component: MedicalReport
          }
        ]
      }
    ]
  },
  {
    path: '/invoices',
    component: Invoices,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next("/login")
        return
      }

      store.dispatch("getProfile");

      next();
    },
    children: [
      {
        path: '/',
        redirect: 'general'
      },
      {
        path: 'general',
        component: GeneralInformation,
      },
      {
        path: 'company',
        component: CompanyStructure,
      },
      {
        path: 'billing',
        component: BillingInformation,
      },
      {
        path: 'card',
        component: CardInformation,
      },
      {
        path: 'account',
        component: AccountSettings,
      },
    ]
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: '/register',
    component: Register,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
