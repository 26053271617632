var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"part mr-6"},[_c('div',{staticClass:"subtitle-2 font-weight-medium mb-2"},[_vm._v("Personal Info")]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("First Name:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.userProfile.firstName)+" ")])]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Last Name:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.userProfile.lastName)+" ")])]),_c('div',{staticClass:"d-flex mb-6"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Mobile Phone:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.userProfile.mobile)+" ")])]),_c('div',{staticClass:"subtitle-2 font-weight-medium mb-2"},[_vm._v("Insurance")]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Policy No:")]),(
            _vm.activeIncident.ownerTraveller.insurancePolicy &&
            _vm.activeIncident.ownerTraveller.insurancePolicy.insurancePolicyNum
          )?_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.insurancePolicy.insurancePolicyNum)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Assistance ref. No:")]),(
            _vm.activeIncident.ownerTraveller.insurancePolicy &&
            _vm.activeIncident.ownerTraveller.insurancePolicy.assistantRefNum
          )?_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.insurancePolicy.assistantRefNum)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Country of Destination:")]),(
            _vm.activeIncident.ownerTraveller.insurancePolicy &&
            _vm.activeIncident.ownerTraveller.insurancePolicy.destCountryName
          )?_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.ownerTraveller.insurancePolicy.destCountryName)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex mb-6"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Travel dates:")]),(
            _vm.activeIncident.ownerTraveller.insurancePolicy &&
            _vm.activeIncident.ownerTraveller.insurancePolicy.policyStartDate &&
            _vm.activeIncident.ownerTraveller.insurancePolicy.policyEndDate
          )?_c('div',{staticClass:"body-1 font-weight-medium text-end"},[_vm._v(" "+_vm._s(_vm.moment .utc( _vm.activeIncident.ownerTraveller.insurancePolicy.policyStartDate ) .format("DD/MM/YYYY"))+" - "+_vm._s(_vm.moment .utc( _vm.activeIncident.ownerTraveller.insurancePolicy.policyEndDate ) .format("DD/MM/YYYY"))+" ")]):_vm._e()]),_c('div',{staticClass:"subtitle-2 font-weight-medium mb-2"},[_vm._v("Doctor Info")]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("First Name:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.selectedDoctor.firstName)+" ")])]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Last Name:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.selectedDoctor.lastName)+" ")])]),_c('div',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"body-1 mr-auto"},[_vm._v("Speciality:")]),_c('div',{staticClass:"body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activeIncident.selectedDoctor.doctorTypeNameEn)+" ")])])]),_c('div',{staticClass:"part map d-flex justify-end"},[_c('GmapMap',{attrs:{"center":{
          lat: _vm.activeIncident.emergencyLocation.lat,
          lng: _vm.activeIncident.emergencyLocation.lon,
        },"zoom":_vm.zoom,"options":_vm.options}},[_c('GmapMarker',{attrs:{"position":{
            lat: _vm.activeIncident.emergencyLocation.lat,
            lng: _vm.activeIncident.emergencyLocation.lon,
          },"draggable":false,"icon":{ url: require('@/assets/red-pin.svg') }}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }