<template>
  <div class="component-wrapper pa-5">
    <ApproveClient v-if="activeIncident.taState == 'ta_pending'" />
    <ApproveIncident v-if="activeIncident.taState == 'ta_clientapproved'" />
    <Cancelled v-if="activeIncident.taState == 'ta_travcancelled'" />
    <Timedout v-if="activeIncident.taState == 'ta_timedout'" />
    <Rejected v-if="activeIncident.taState == 'ta_rejected'" />
    <Approved v-if="activeIncident.taState == 'ta_approved'" />

    <v-tabs height="60px" class="mb-4">
      <v-tab
        :disabled="tab.disabled"
        v-for="(tab, i) in tabs"
        :key="i"
        :to="tab.path"
        exact
      >
        {{ tab.text }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>

    <!-- <IncidentInformation /> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import IncidentInformation from "@/views/incidents/incident-details/IncidentInformation";
import MedicalReport from "@/views/incidents/incident-details/MedicalReport";
import ApproveClient from "@/views/incidents/incident-details/ApproveClient";
import ApproveIncident from "@/views/incidents/incident-details/ApproveIncident";
import Cancelled from "@/views/incidents/incident-details/Cancelled";
import Timedout from "@/views/incidents/incident-details/Timedout";
import Rejected from "@/views/incidents/incident-details/Rejected";
import Approved from "@/views/incidents/incident-details/Approved";

export default {
  components: {
    IncidentInformation,
    MedicalReport,
    ApproveClient,
    ApproveIncident,
    Cancelled,
    Timedout,
    Rejected,
    Approved,
  },

  created() {
    console.log(this.activeIncident);
    this.tabs.push(
      {
        text: "General Information",
        path: `/incidents/${this.activeIncident.emergencyId}/general-information`,
        disabled: false,
      },

      {
        text: "Medical Report",
        path: `/incidents/${this.activeIncident.emergencyId}/medical-report`,
        disabled: this.activeIncident.medicalReport != undefined ? false : true,
      }
    );
  },

  data() {
    return {
      tabs: [],
    };
  },

  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
  },
  beforeRouteUpdate(to, from, next) {
    this.setActiveIncident(to.params.id);

    this.tabs[0].path = `/incidents/${this.activeIncident.emergencyId}/general-information`;
    this.tabs[1].path = `/incidents/${this.activeIncident.emergencyId}/medical-report`;
    if (this.activeIncident.medicalReport == undefined)
      this.tabs[1].disabled = true;
    else this.tabs[1].disabled = false;

    // if (
    //   this.activeIncident &&
    //   this.activeIncident.emergencyId != from.params.id
    // ) {
    //   next();
    // } else {
    //   console.log("NOOOO");
    //   next("/incidents");
    // }
    next();
  },
  methods: {
    ...mapMutations(["setActiveIncident"]),
  },
};
</script>

<style lang="scss" scoped>
.blocked {
  cursor: not-allowed;
}
</style>