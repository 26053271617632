<template>
  <div>
    <template
      v-if="
        activeIncident.medicalReport && activeIncident.medicalReport.isFinal
      "
    >
      <div class="subtitle-2 font-weight-medium mb-2">1. Patient’s ID</div>
      <div class="photos-container mb-4">
        <img
          v-for="(photo, index) in activeIncident.medicalReport.attachments"
          :key="index"
          :src="photo.attachmentUrl"
        />
      </div>

      <div class="subtitle-2 font-weight-medium mb-2">2. Report</div>

      <div
        class="body-2"
        :class="{ 'mb-4': !activeIncident.medicalReport.referralSpecialityId }"
      >
        Referral to a specialist:
        {{ activeIncident.medicalReport.referralSpecialityId ? "yes" : "no" }}
      </div>
      <div
        class="body-2 mb-4"
        v-if="activeIncident.medicalReport.referralSpecialityId"
      >
        Patient needs to be further evaluated by doctor specializing in:
        <span class="font-weight-medium">{{ doctorTypeName }}</span>
      </div>

      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            class="font-weight-medium body-2 pb-2"
            >A. Diagnosis / First Estimate*</v-expansion-panel-header
          >
          <v-expansion-panel-content class="pt-2">
            <v-textarea
              no-resize
              class="align-self-stretch flex-grow-0 mb-2"
              rows="2"
              :value="activeIncident.medicalReport.diagnosisText"
              readonly
            ></v-textarea>

            <div class="d-flex flex-column align-center">
              <v-chip class="mb-1">{{
                activeIncident.medicalReport.iCD10Code
              }}</v-chip>
              <div class="caption text-center">
                {{ activeIncident.medicalReport.iCD10Description }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            class="font-weight-medium body-2 pb-2"
          >
            B. Further examinations
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <v-treeview
              :items="examinationsTypes"
              expand-icon="mdi-menu-down"
              v-if="examinationsTypes.length > 0"
            ></v-treeview>
            <div class="body-1" v-else>No further examinations needed</div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            class="font-weight-medium body-2 pb-2"
            >C. Treatment / Medication*</v-expansion-panel-header
          >
          <v-expansion-panel-content class="pt-2">
            <v-textarea
              no-resize
              class="align-self-stretch flex-grow-0 mb-2"
              rows="2"
              :value="activeIncident.medicalReport.treatmentText"
              readonly
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="mb-8">
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            class="font-weight-medium body-2 pb-2"
            >D. Instructions / Notes</v-expansion-panel-header
          >
          <v-expansion-panel-content class="pt-2">
            <v-textarea
              no-resize
              class="align-self-stretch flex-grow-0 mb-2"
              rows="2"
              :value="activeIncident.medicalReport.instructionsText"
              readonly
              v-if="activeIncident.medicalReport.instructionsText"
            ></v-textarea>

            <div class="body-1" v-else>No Instructions / Notes added.</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <v-progress-circular
      v-if="activeIncident.hasFinalMedicalReport"
      indeterminate
      color="primary"
      class="mx-auto mt-12"
      size="50"
      width="3"
    ></v-progress-circular>

    <template
      v-if="
        activeIncident.medicalReport && !activeIncident.medicalReport.isFinal
      "
    >
      <div
        class="
          d-flex
          align-center align-self-start
          pa-2
          grey
          draft
          lighten-2
          mr-8
        "
        @click="onGetMedicalReport"
      >
        <svg class="report-icon mr-3">
          <use xlink:href="@/assets/sprite.svg#icon-report" />
        </svg>

        <div class="d-flex flex-column">
          <div class="caption">Draft last saved on</div>
          <div class="caption">
            {{
              moment
                .utc(activeIncident.medicalReport.submittedOn)
                .local()
                .format("DD/MM/YYYY HH:mm")
            }}
          </div>
        </div>

        <v-progress-circular
          v-if="getMedicalReportLoader"
          indeterminate
          color="primary"
          size="15"
          width="2"
          class="edit-icon"
        ></v-progress-circular>

        <svg class="edit-icon" v-else>
          <use xlink:href="@/assets/sprite.svg#icon-edit" />
        </svg>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    if (this.activeIncident?.medicalReport?.furtherExaminations) {
      this.activeIncident.medicalReport.furtherExaminations.map(
        (examination) => {
          const found = this.examinationsTypes.findIndex(
            (e) => e.id == examination.examinationTypeId
          );
          if (found == -1) {
            this.examinationsTypes.push({
              id: examination.examinationTypeId,
              name: examination.examinationTypeName,
              children: [
                {
                  id: examination.examinationId,
                  name: examination.examinationName,
                },
              ],
            });
          } else {
            this.examinationsTypes[found].children.push({
              id: examination.examinationId,
              name: examination.examinationName,
            });
          }
        }
      );
    }
  },

  data() {
    return {
      examinationsTypes: [],
    };
  },

  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),

    doctorTypeName() {
      if (!this.activeIncident.hasFinalMedicalReport) return null;

      return this.doctorTypes.find(
        (doctorType) =>
          doctorType.option_key ==
          this.activeIncident.medicalReport.referralSpecialityId
      ).option_value;
    },
  },
};
</script>

<style scoped lang="scss">
.photo-container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 80px);
  grid-auto-rows: 80px;
  grid-gap: 10px;
  grid-auto-flow: dense;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.create-medical-report {
  border: 1px dashed #bdbdbd;
  border-radius: 4px;
  height: 50px;
}

.draft {
  border-radius: 4px;
  position: relative;

  .report-icon {
    height: 30px;
    width: 30px;
  }

  .edit-icon {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    fill: #424242;
    height: 20px;
    width: 20px;
  }
}
</style>