var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-wrapper d-flex flex-column pa-5"},[(_vm.incidentsLoader)?_c('v-progress-linear',{staticClass:"mb-5",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.incidentsLoader && _vm.incidents.length == 0 && !_vm.getIncidentsError)?[_c('img',{staticClass:"align-self-center mb-2 mt-12",attrs:{"src":require("@/assets/cactus.png")}}),_c('div',{staticClass:"text-h6 font-weight-medium text-center mb-1"},[_vm._v(" No incidents yet ")]),_c('div',{staticClass:"body-1 text-center"},[_vm._v("Enjoy your day")])]:_vm._e(),(!_vm.incidentsLoader && _vm.incidents.length > 0 && !_vm.getIncidentsError)?[_c('div',{staticClass:"text-h5 font-weight-medium text-center align-self-center mb-4"},[_vm._v(" Incidents ")]),_c('div',{staticClass:"scrollable mb-4"},_vm._l((_vm.incidents),function(incident,index){return _c('div',{key:incident.emergencyId,staticClass:"incident-card",class:{
          active:
            _vm.activeIncident &&
            _vm.activeIncident.emergencyId == incident.emergencyId,
        },on:{"click":function($event){return _vm.$router.push({
            path: ("/incidents/" + (incident.emergencyId) + "/general-information"),
          })}}},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"photo-wrapper mr-4"},[_c('img',{attrs:{"src":incident.ownerTraveller.userProfile.profilePic}})]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"subtitle-1 font-weight-medium mb-1"},[_vm._v(" "+_vm._s(incident.ownerTraveller.userProfile.firstName)+" "+_vm._s(incident.ownerTraveller.userProfile.lastName)+" ")]),_c('div',{staticClass:"caption mb-1"},[_vm._v(" "+_vm._s(incident.emergencyLocation.countryName)+" ")]),(
                  incident.taState == 'ta_pending' ||
                  incident.taState == 'ta_clientapproved'
                )?_c('div',{staticClass:"caption primary--text font-weight-medium"},[_vm._v(" Pending approval ")]):_vm._e(),(incident.taState == 'ta_timedout')?_c('div',{staticClass:"d-flex caption error--text font-weight-medium"},[_c('div',{staticClass:"mr-2"},[_vm._v("Timed out:")]),_c('div',[_vm._v(" "+_vm._s(_vm.moment .utc(incident.taTimedOutOn) .local() .format("DD/MM/YY HH:mm"))+" ")])]):_vm._e(),(incident.taState == 'ta_rejected')?_c('div',{staticClass:"d-flex caption error--text font-weight-medium"},[_c('div',{staticClass:"mr-2"},[_vm._v("Rejected:")]),_c('div',[_vm._v(" "+_vm._s(_vm.moment .utc(incident.taRespondedOn) .local() .format("DD/MM/YY HH:mm"))+" ")])]):_vm._e(),(incident.taState == 'ta_approved')?_c('div',{staticClass:"d-flex caption success--text font-weight-medium"},[_c('div',{staticClass:"mr-2"},[_vm._v("Approved:")]),_c('div',[_vm._v(" "+_vm._s(_vm.moment .utc(incident.taRespondedOn) .local() .format("DD/MM/YY HH:mm"))+" ")])]):_vm._e(),(incident.taState == 'ta_travcancelled')?_c('div',{staticClass:"caption error--text font-weight-medium"},[_vm._v(" Cancelled by patient ")]):_vm._e()]),_c('div',{staticClass:"caption incident-date text-center ml-auto"},[_vm._v(" "+_vm._s(_vm.moment .utc(incident.taNotifiedOn) .local() .format("DD/MM/YY HH:mm"))+" ")])])]),(index != _vm.incidents.length - 1)?_c('v-divider',{staticClass:"mt-2"}):_vm._e()],1)}),0)]:_vm._e(),(
      (!_vm.incidentsLoader || _vm.numOfPages) &&
      _vm.incidents.length > 0 &&
      !_vm.getIncidentsError
    )?_c('v-pagination',{staticClass:"mt-auto",attrs:{"value":_vm.currentPage,"total-visible":5,"length":_vm.numOfPages},on:{"input":_vm.onChangePage}}):_vm._e(),(_vm.getIncidentsError)?_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v(" There was an error while fetching your incidents. Please, try again later. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }