import { render, staticRenderFns } from "./IncidentInformation.vue?vue&type=template&id=55ecbfb4&scoped=true&"
import script from "./IncidentInformation.vue?vue&type=script&lang=js&"
export * from "./IncidentInformation.vue?vue&type=script&lang=js&"
import style0 from "./IncidentInformation.vue?vue&type=style&index=0&id=55ecbfb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ecbfb4",
  null
  
)

export default component.exports