<template>
  <div>
    <div class="d-flex flex-column align-center mb-2">
      <v-icon color="error" class="mb-1" x-large>mdi-close</v-icon>

      <div class="text-end text-h6 mb-1">
        The patient has cancelled this request.
      </div>

      <div class="body-1 text-center">
        It looks like the patient has cancelled this request before your response. Any actions taken
        concerning this request will be discarded.
      </div>
    </div>

    <v-divider class="my-5"></v-divider>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>