<template>
  <div>
    <div class="body-2 text-end mb-2">Pending client approval.</div>

    <div class="text-h6 font-weight-regular primary--text text-end">
      {{ timer }}
    </div>

    <v-divider class="my-5"></v-divider>

    <div class="d-flex">
      <div class="text-field-wrapper mr-auto">
        <v-text-field
          outlined
          dense
          label="Excess Waiver"
          type="number"
          v-model.number="excessWaiver"
          hint="Excess waiver is required. Type '0' if you would like to approve client without excess waiver."
          persistent-hint
          :error-messages="excessWaiverErrors"
        ></v-text-field>
      </div>

      <v-btn
        color="error"
        depressed
        rounded
        width="90px"
        class="mr-2"
        @click="onTravellerDeny"
        :loading="denyLoader"
        >Deny</v-btn
      >
      <v-btn
        color="success"
        depressed
        rounded
        width="90px"
        @click="onTravellerAccept"
        :loading="acceptLoader"
        >Confirm</v-btn
      >
    </div>

    <v-divider class="my-5"></v-divider>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  data() {
    return {
      excessWaiver: null,
      denyLoader: false,
      acceptLoader: false,
      interval: null,
      remaining: null,
    };
  },
  validations: {
    excessWaiver: { required },
  },
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
    excessWaiverErrors() {
      const errors = [];
      if (!this.$v.excessWaiver.$dirty) return errors;
      if (!this.$v.excessWaiver.required)
        errors.push(
          "Excess waiver is required. Type '0' if you would like to approve client without excess waiver."
        );
      return errors;
    },
    timer() {
      if (this.remaining <= 0) return "00:00:00";

      let minutes = Math.floor(this.remaining / 60);
      let seconds = this.remaining % 60;
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;

      hours = hours.toString().padStart(2, "0");
      minutes = minutes.toString().padStart(2, "0");
      seconds = seconds.toString().padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  created() {
    const secondsUntilTimeout =
      this.activeIncident.secondsForTAClientAcceptance +
      this.activeIncident.secondsForTAIncidentAcceptance;

    const taNotifiedOn = moment.utc(this.activeIncident.taNotifiedOn).local();
    const now = moment.utc(new Date()).local();

    const diffSeconds = Math.floor(
      moment.duration(now.diff(taNotifiedOn)).asSeconds()
    );

    this.remaining = secondsUntilTimeout - diffSeconds;

    this.interval = setInterval(() => {
      if (this.remaining <= 0) {
        clearInterval(this.interval);
        return;
      }

      this.remaining--;
    }, 1000);
  },
  methods: {
    ...mapActions(["travellerResponse", "getIncidents"]),
    ...mapMutations(["setActiveIncident"]),
    async onTravellerDeny() {
      this.denyLoader = true;

      try {
        await this.travellerResponse({
          response: false,
          excessWaiver: 0,
        });

        await this.getIncidents(true);
        this.setActiveIncident(this.activeIncident.emergencyId);
      } catch (e) {
        console.log(e);
      }

      this.denyLoader = false;
    },
    async onTravellerAccept() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.acceptLoader = true;

      try {
        await this.travellerResponse({
          response: true,
          excessWaiver: this.excessWaiver,
        });

        await this.getIncidents(true);
        this.setActiveIncident(this.activeIncident.emergencyId);
      } catch (e) {
        console.log(e);
      }

      this.acceptLoader = false;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.text-field-wrapper {
  max-width: 350px;
}
</style>