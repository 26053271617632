<template>
  <div class="component-wrapper d-flex flex-column justify-start align-center">
    <form
      class="form elevation-3 d-flex flex-column pa-8 pt-5 rounded"
      @submit.prevent="submit"
      novalidate
    >
      <div class="text-h6 text-center mb-4">Travel Assistance Registration</div>

      <v-text-field
        outlined
        dense
        label="Email"
        class="mb-1"
        v-model="email"
        :error-messages="emailErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="Password"
        type="password"
        class="mb-1"
        v-model="password"
        :error-messages="passwordErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="Confirm password"
        type="password"
        class="mb-1"
        v-model="confirmPassword"
        :error-messages="confirmPasswordErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="First Name"
        class="mb-1"
        v-model="firstName"
        :error-messages="firstNameErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="Last Name"
        class="mb-1"
        v-model="lastName"
        :error-messages="lastNameErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="Phone number"
        class="mb-1"
        v-model="phoneNumber"
        :error-messages="phoneNumberErrors"
      >
      </v-text-field>

      <v-text-field
        outlined
        dense
        label="Company Name"
        class="mb-1"
        v-model="registeredName"
        :error-messages="registeredNameErrors"
      >
      </v-text-field>

      <v-autocomplete
        outlined
        dense
        label="Country"
        class="mb-1"
        v-model="country"
        :items="countries"
        item-text="option_value"
        item-value="option_key"
        :error-messages="countryErrors"
      ></v-autocomplete>

      <v-btn color="primary" type="submit" :loading="loader" class="mb-5"
        >Register</v-btn
      >
    </form>
  </div>
</template>
  
  <script>
import axios from "axios";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  async created() {
    try {
      await this.getCountries();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      firstName: null,
      lastName: null,
      country: null,
      phoneNumber: null,
      registeredName: null,
      loader: false,
    };
  },

  validations: {
    email: { required, email },

    password: {
      required,
      minLength: minLength(4),
    },

    confirmPassword: {
      required,
      sameAs: sameAs("password"),
    },

    firstName: { required },
    lastName: { required },

    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(12),
    },
    registeredName: { required },
    country: { required },
  },

  computed: {
    ...mapState({
      countries: (state) => state.base.countries,
    }),

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push("Required field");
      if (!this.$v.email.email) return "Please, enter a valid email format";

      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Required field");
      if (!this.$v.password.minLength)
        errors.push("The password should have at least 4 characters");

      return errors;
    },

    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      if (!this.$v.confirmPassword.required) errors.push("Required field");
      if (!this.$v.confirmPassword.sameAs)
        errors.push("Please make sure your passwords match");

      return errors;
    },

    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      if (!this.$v.firstName.required) errors.push("Required field");

      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      if (!this.$v.lastName.required) errors.push("Required field");

      return errors;
    },

    registeredNameErrors() {
      const errors = [];
      if (!this.$v.registeredName.$dirty) return errors;
      if (!this.$v.registeredName.required) errors.push("Required field");

      return errors;
    },

    countryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;
      if (!this.$v.country.required) errors.push("Required field");

      return errors;
    },

    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required) errors.push("Required field");
      if (!this.$v.phoneNumber.minLength || !this.$v.phoneNumber.maxLength)
        return "Please enter a valid phone number";

      return errors;
    },
  },

  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["getCountries"]),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loader = true;

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/user/register`,
          {
            firstName: this.firstName,
            lastName: this.lastName,
            homeContryIso: this.country,
            email: this.email,
            password: this.password,
            companyName: this.companyName,
            userRole: "ta_admin",
            application: "app_ta",
          }
        );

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;

        await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/companyProfile`, {
          registeredName: this.registeredName,
          phoneNo: this.phoneNumber,
        });

        delete axios.defaults.headers.common["Authorization"];

        this.$router.push("/login");

        this.toggleSnackbar({
          open: true,
          text: "You have been registered. We will notify you via email when you get approved!",
          color: "success",
          timeout: 5000,
        });
      } catch (e) {
        if (
          e?.response?.data.errorCode == 10413 ||
          e?.response?.data.errorCode == 10413 ||
          e?.response?.data.errorCode == 10414 ||
          e?.response?.data.errorCode == 10415
        ) {
        } else {
          this.toggleSnackbar({
            open: true,
            text: `${e?.response?.data?.formErrors[0]?.errorMessage}`,
            color: "error",
            timeout: 5000,
          });
          console.log(e);
        }
      }

      this.loader = false;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.form {
  margin-top: 50px;
}
</style>