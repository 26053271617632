import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate'
import './registerServiceWorker'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-open-sans';
import firebase from "firebase/app";
import * as VueGoogleMaps from 'vue2-google-maps'
import axios from "axios";

Vue.use(Vuelidate)

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyCJ8__cbh_Lk1aYjcgnJCExmyMW3AnrEro",
  projectId: "dn2me-production",
  messagingSenderId: "515819616233",
  appId: "1:515819616233:web:1a621ec780d73d96f5bd78",
};

firebase.initializeApp(firebaseConfig);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBWLCJCZTxoNoeRE059LPwZwP4wcCX1yCQ',
    libraries: 'places'
  }
})

if (store.getters.isLoggedIn) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${store.state.auth.token}`;
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
