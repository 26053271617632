<template>
  <div class="component-wrapper pt-5">
    <div class="text-h6 mb-5">Change your password</div>

    <form @submit.prevent="submit" novalidate>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Old Password"
            outlined
            dense
            prepend-inner-icon="mdi-lock"
            v-model="oldPassword"
            type="password"
            class="mb-1"
            :error-messages="wrongOldPassword ? 'Wrong password.' : ''"
          ></v-text-field>

          <v-text-field
            label="New Password"
            outlined
            dense
            prepend-inner-icon="mdi-lock"
            v-model="newPassword"
            type="password"
            class="mb-1"
            :error-messages="newPasswordErrors"
          ></v-text-field>

          <v-text-field
            label="Confirm new Password"
            outlined
            dense
            prepend-inner-icon="mdi-lock"
            v-model="repeatPassword"
            type="password"
            class="mb-1"
            :error-messages="newPasswordErrors"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        type="submit"
        :loading="loader"
        :disabled="!oldPassword || !newPassword || !repeatPassword"
      >
        Change
      </v-btn>
    </form>

    <v-dialog v-model="dialog" max-width="500">
      <div class="white pa-4">
        <div class="body-1 text-center mb-2">
          You have unsaved changes. Discard without saving?
        </div>

        <div class="d-flex justify-center">
          <v-btn
            color="error"
            small
            class="mr-2"
            @click="$router.push({ path: leavePath })"
            >Discard</v-btn
          >

          <v-btn small color="primary" @click="dialog = false">Stay</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      wrongOldPassword: false,
      loader: false,
      initialData: null,
      dialog: false,
      leavePath: false,
    };
  },
  validations: {
    oldPassword: { required },
    newPassword: { required },
    repeatPassword: { required, sameAsPassword: sameAs("newPassword") },
  },
  computed: {
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      if (!this.$v.repeatPassword.sameAsPassword)
        errors.push("Passwords do not match");
      return errors;
    },
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          repeatPassword: this.repeatPassword,
        })
      );
    },
  },
  created() {
    this.initialData = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      repeatPassword: this.repeatPassword,
    };
  },
  methods: {
    ...mapActions(["changePassword"]),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.wrongOldPassword = false;
      this.loader = true;

      try {
        await this.changePassword({
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        });

        this.oldPassword = "";
        this.newPassword = "";
        this.repeatPassword = "";

        this.initialData = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          repeatPassword: this.repeatPassword,
        };
      } catch (e) {
        if (e.response?.data?.errorCode == 10413) {
          this.wrongOldPassword = true;
        }
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasChanges || this.leavePath == to.path) {
      next();
    } else {
      this.leavePath = to.path;
      this.dialog = true;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>