<template>
  <div>
    <div class="body-2 success--text font-weight-medium text-end">Approved</div>

    <v-divider class="my-5"></v-divider>

    <div class="d-flex align-center">
      <div class="body-2 ml-auto mr-2">Incident approved after:</div>
      <div class="text-h6 font-weight-regular primary--text">{{ rejectedAfter }}</div>
    </div>

    <v-divider class="my-5"></v-divider>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
    rejectedAfter() {
      const start = moment.utc(this.activeIncident.taNotifiedOn).local();
      const end = moment.utc(this.activeIncident.taRespondedOn).local();

      const diff = moment.duration(end.diff(start));

      const hours = Math.floor(diff.asHours()).toString().padStart(2, "0");
      const minutes = Math.floor(diff.asMinutes()).toString().padStart(2, "0");
      const seconds = Math.floor(diff.asSeconds()).toString().padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>