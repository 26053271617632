<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-icon color="grey lighten-2"> mdi-clipboard-text </v-icon>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.component-wrapper {
  padding: 20px;
}

.v-icon {
  font-size: 150px;
}
</style>