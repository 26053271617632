<template>
  <div>
    <div class="d-flex flex-column align-center mb-2">
      <v-icon color="error" class="mb-1" x-large>mdi-timer-off-outline</v-icon>

      <div class="text-end text-h6 mb-1">The request timed out.</div>

      <div class="body-1 text-center">
        You did not respond within time limit and the request timed out.
      </div>
    </div>

    <v-divider class="my-5"></v-divider>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
  },
};
</script>

<style lang="scss" scoped>
</style>