import axios from 'axios'

export default {
    state: {
        profile: null,
        profileLoader: false,
        managers: [],
        operators: [],
        stripeClientSecret: null,
    },
    mutations: {
        getProfile(state, profile) {
            state.profile = profile
        },
        getStripeClientSecret(state, secret) {
            state.stripeClientSecret = secret
        },
        setProfile(state, profile) {
            state.profile = { ...state.profile, ...profile };
        },
        toggleProfileLoader(state, loader) {
            state.profileLoader = loader;
        },
        setSubUsers(state, subusers) {
            state.managers = subusers.managers;
            state.operators = subusers.operators;
        },
        pushSubuser(state, subuser) {
            if (subuser.role == 'ta_finance') {
                state.managers.push(subuser);
            } else {
                state.operators.push(subuser);
            }
        },
        deleteSubuser(state, payload) {
            if (payload.role == 'ta_finance') {
                state.managers.splice(
                    state.managers.findIndex(
                        manager => manager.userId == payload.userId
                    ),
                    1
                )
            } else {
                state.operators.splice(
                    state.managers.findIndex(
                        manager => manager.userId == payload.userId
                    ),
                    1
                )
            }
        }
    },
    actions: {
        async getSecretClient({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ta/paymentProfile`)
                commit('getStripeClientSecret', res.data.paymentProfile.stripeClientSecret)
            }
            catch (e) {
                console.log(e)
            }
        },

        async addPaymentInfo({ dispatch }, payload) {
            try {
                const res = await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/ta/completePaymentProfile`,
                    {
                        params: {
                            setup_intent: payload.setupIntent.id
                        },
                    }
                );


                await dispatch("getProfile", true);




            } catch (e) {

                throw e;
            }
        },


        async getProfile({ commit, state, dispatch }, refresh = false) {
            if (state.profile && !refresh) return;

            try {
                commit("toggleProfileLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ta/myProfile`);
                commit("getProfile", res.data);


                await dispatch("getSubUsers");

                commit("toggleProfileLoader", false);
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })
                throw e;
            }
        },
        async getSubUsers({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ta/subUserProfileList`);

                const managers = [];
                const operators = [];

                res.data.forEach(subuser => {
                    if (subuser.userRole == "ta_finance") {
                        managers.push({
                            firstName: subuser.firstname,
                            lastName: subuser.lastname,
                            phone: subuser.mobile,
                            email: subuser.email,
                            userId: subuser.userId
                        });
                    } else if (subuser.userRole == "ta_operation") {
                        operators.push({
                            firstName: subuser.firstname,
                            lastName: subuser.lastname,
                            phone: subuser.mobile,
                            email: subuser.email,
                            userId: subuser.userId
                        });
                    }
                });

                commit("setSubUsers", {
                    managers,
                    operators
                })
            } catch (e) {
                throw e;
            }
        },
        async addSubUser({ commit }, subuser) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/subUserProfileCreate`, {
                    firstName: subuser.firstName,
                    lastName: subuser.lastName,
                    mobile: subuser.phoneNumber,
                    email: subuser.email,
                    userRole: subuser.role
                })

                commit("pushSubuser", {
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    phone: res.data.mobile,
                    email: res.data.email,
                    userId: res.data.userId,
                    role: subuser.role
                })

                commit("toggleSnackbar", {
                    open: true,
                    text: `${subuser.role == 'ta_finance' ? 'Finance Manager' : 'Operator'} has been added!`,
                    color: "primary",
                    timeout: 5000
                })
            } catch (e) {
                if (e?.response?.data?.errorCode != 1002) {
                    commit("toggleSnackbar", {
                        open: true,
                        text: "Something went wrong. Please try again later.",
                        color: "error",
                        timeout: 5000
                    })
                }
                throw e;
            }
        },
        async deleteSubuser({ commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/subUserProfileDelete`, {
                    userId: payload.userId
                })

                commit("deleteSubuser", {
                    userId: payload.userId,
                    role: payload.role
                });

                commit("toggleSnackbar", {
                    open: true,
                    text: `${payload.role == 'ta_finance' ? 'Finance Manager' : 'Operator'} has been deleted`,
                    color: "primary",
                    timeout: 5000
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })
                throw e;
            }
        },
        async saveGeneralInfo({ commit, dispatch }, generalInfo) {
            try {
                await Promise.all([
                    axios.post(`${process.env.VUE_APP_BASE_URL}/ta/userProfile`, {
                        ...generalInfo.userProfile
                    }),
                    axios.post(`${process.env.VUE_APP_BASE_URL}/ta/companyprofile`, {
                        ...generalInfo.companyProfile
                    })
                ])

                dispatch("getProfile", true);

                commit("toggleSnackbar", {
                    open: true,
                    text: "General Information has been saved!",
                    color: "success",
                    timeout: 5000
                });
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })

                throw e;
            }
        },
        async saveBillingInfo({ commit, dispatch }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/billingProfile`, {
                    ...payload.billingInfo
                })

                dispatch("getProfile", true);

                if (payload.showSnack)
                    commit("toggleSnackbar", {
                        open: true,
                        text: "Billing Information has been saved!",
                        color: "success",
                        timeout: 5000
                    });
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })
                throw e;
            }
        },
        async changePassword({ commit }, passwords) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/user/updatePassword`, {
                    newPassword: passwords.newPassword,
                    token: passwords.oldPassword
                });

                commit("toggleSnackbar", {
                    open: true,
                    text: "Your password has been updated!",
                    color: "success",
                    timeout: 5000
                })
            } catch (e) {
                if (e.response?.data?.errorCode != 10413) {
                    commit("toggleSnackbar", {
                        open: true,
                        text: "Something went wrong. Please try again later.",
                        color: "error",
                        timeout: 5000
                    })
                }
                throw e;
            }
        }
    },
}
