<template >
  <div class="component-wrapper d-flex flex-column">
    <div class="text-h5 font-weight-medium">Dashboard</div>

    <div class="inner-wrapper align-self-center text-center">
      <img
        src="@/assets/presentation.svg"
        class="align-self-center dashboard-icon mb-2"
      />

      <div class="text-h6 mb-2">Hi,</div>

      <div class="body-1">
        This is your Dashboard. Once you start receiving incident requests, you
        will be able to access here information and statistics regarding the
        incidents and the general activity in your system.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.dashboard-icon {
  width: 50px;
}

.inner-wrapper {
  margin-top: 100px;
}
</style>
