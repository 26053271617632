import axios from 'axios'
import firebase from "firebase/app";
import "firebase/messaging";

export default {
    state: {
        token: localStorage.getItem('token') || null
    },
    getters: {
        isLoggedIn: state => !!state.token
    },
    mutations: {
        login(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = null;
        }
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_BASE_URL}/user/login`, {
                    username: credentials.username,
                    password: credentials.password,
                    application: "app_ta",
                })

                const token = res.data.token;
                localStorage.setItem('token', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                await dispatch("registerNotifications");

                commit('login', token)

            } catch (e) {
                if (
                    e?.response?.data?.errorCode != 10413 &&
                    e?.response?.data?.errorCode != 10414 &&
                    e?.response?.data?.errorCode != 10415
                ) {
                    commit("toggleSnackbar", {
                        open: true,
                        text: "Something went wrong. Please try again later.",
                        color: "error",
                        timeout: 5000
                    })
                }

                dispatch("logout");
                throw e;
            }
        },
        async registerNotifications({ }) {
            try {
                const messaging = firebase.messaging();

                const token = await messaging.getToken({
                    vapidKey:
                        "BKHKaQ_6Pdep2PxwVTA31afHH0YxyhgyETszkh43PEFXOIvTH_zVgE9yqcPrDICKFDHUdO7moflsNTvDt7YchnY",
                });

                await axios.get(
                    `${process.env.VUE_APP_BASE_URL}/user/notifToken/${token}/web`
                )
            } catch (e) {
                throw e;
            }
        },
        async forgotPassword({ commit }, email) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/user/requestPassReset`, {
                    email
                })
            } catch (e) {
                if (e.response?.data?.errorCode != 10403) {
                    commit("toggleSnackbar", {
                        open: true,
                        text: "Something went wrong. Please try again later.",
                        color: "error",
                        timeout: 5000
                    })
                }

                throw e
            }
        },
        async logout({ commit }) {
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];

            const messaging = firebase.messaging();

            try {
                await messaging.deleteToken();
            } catch (e) {
            }

            commit('logout');
        }
    },
}
