<template>
  <v-dialog v-model="discardDialog" max-width="500">
    <div class="white pa-4">
      <div class="body-1 text-center mb-2">
        You have unsaved changes. Discard without saving?
      </div>

      <div class="d-flex justify-center">
        <v-btn color="error" small class="mr-2" @click="onDiscard"
          >Discard</v-btn
        >

        <v-btn small color="primary" @click="toggleDiscardDialog(false)"
          >Stay</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["leavePath", "initialize"],
  computed: {
    ...mapState({
      discardDialog: (state) => state.base.discardDialog,
    }),
  },
  methods: {
    ...mapMutations(["toggleDiscardDialog"]),
    onDiscard() {
      this.initialize();
      this.toggleDiscardDialog(false);
      this.$router.push({ path: this.leavePath });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>