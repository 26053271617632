<template>
  <v-app id="dn2me">
    <audio ref="notif" src="notif.mp3" preload="auto"></audio>

    <Navigation />

    <v-main>
      <div class="sidebar" v-if="isLoggedIn">
        <Sidebar />
      </div>

      <v-progress-linear
        v-if="profileLoader"
        indeterminate
        color="primary"
        class="ma-5"
      ></v-progress-linear>

      <router-view v-else></router-view>
    </v-main>

    <v-snackbar
      v-model="snackbar.open"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>

        <v-btn
          text
          small
          class="ml-auto"
          @click="toggleSnackbar({ open: false })"
          >Close</v-btn
        >
      </div>
    </v-snackbar>

    <Footer />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";
import Sidebar from "@/components/Sidebar";
import Footer from "@/components/Footer";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/messaging";
import { App } from "@capacitor/app";

export default {
  components: {
    Navigation,
    Sidebar,
    Footer,
  },
  data() {
    return {
      refetchIncidents: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      token: (state) => state.auth.token,
      profileLoader: (state) => state.profile.profileLoader,
    }),
  },
  async created() {
    //====== INIT STUFF ======
    if (this.isLoggedIn) await this.getProfile(true);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status === 401) {
          await this.logout();
          this.$router.push({ path: "/login" });
        }
        throw error;
      }
    );

    //------ login stuff ------
    if (this.isLoggedIn) {
      try {
        await this.registerNotifications();
      } catch (e) {
        await this.logout();
        this.toggleSnackbar({
          open: true,
          text: "Something went wrong. Please try again later.",
          color: "error",
          timeout: 5000,
        });
        this.$router.push({ path: "/login" });
      }
    }

    //====== APP STATE CHANGE LISTENER ======

    App.addListener("appStateChange", async (state) => {
      if (state.isActive && !!this.refetchIncidents) {
        await this.onGetIncidents(true);

        let color;
        let text;

        if (this.refetchIncidents == "traveller_emergsubmitted") {
          text = "You have a new case!";
          color = "primary";
        } else if (this.refetchIncidents == "ta_timeout") {
          text = "Emergency timed out.";
          color = "error";
        } else if (this.refetchIncidents == "traveller_emergcancelled") {
          text = "Traveller cancelled the emergency.";
          color = "error";
        }

        this.toggleSnackbar({
          open: true,
          text,
          color,
          timeout: 5000,
        });

        this.refetchIncidents = false;
      }
    });

    //====== NOTIFICATION STUFF ======
    const messaging = firebase.messaging();

    //------ foreground notifications ------
    messaging.onMessage(async (payload) => {
      console.log("Message received directly IN client ", payload);

      //------ incident submitted ------
      if (payload.data.type == "traveller_emergsubmitted") {
        this.toggleSnackbar({
          open: true,
          text: "You have a new case!",
          color: "primary",
          timeout: 5000,
        });

        this.$refs.play();

        await this.onGetIncidents(true);
      }

      //------ timeout ------
      if (payload.data.type == "ta_timeout") {
        this.toggleSnackbar({
          open: true,
          text: "Emergency timed out.",
          color: "error",
          timeout: 5000,
        });

        await this.onGetIncidents(true);
      }

      //------ traveller cancelled ------
      if (payload.data.type == "traveller_emergcancelled") {
        this.toggleSnackbar({
          open: true,
          text: "Traveller cancelled the emergency.",
          color: "error",
          timeout: 5000,
        });

        await this.onGetIncidents(true);
      }
    });

    //------ background notifications ------
    navigator?.serviceWorker?.addEventListener("message", async (event) => {
      const appState = await App.getState();

      if (!appState.isActive) {
        console.log(event?.data?.data?.type);

        if (
          event?.data?.data?.type == "traveller_emergsubmitted" ||
          event?.data?.data?.type == "traveller_emergcancelled" ||
          event?.data?.data?.type == "ta_timeout"
        ) {
          this.refetchIncidents = event?.data?.data?.type; //string is casted to true. check app state change listener
        }

        if (event?.data?.data?.type == "traveller_emergsubmitted") {
          this.$refs.notif.play();
        }
      }
    });
  },
  methods: {
    ...mapActions([
      "registerNotifications",
      "logout",
      "getIncidents",
      "getProfile",
    ]),
    ...mapMutations(["toggleSnackbar"]),
    async onGetIncidents() {
      try {
        await this.getIncidents(true);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
//====== VUETIFY OVERRIDES ======
.card {
  background-color: white !important;
  width: 100vw;
  height: 100vh;
}
#dn2me.v-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: "Open Sans", sans-serif !important;
  }

  .text-h6,
  .font-weight-medium {
    font-weight: 600 !important;
  }

  .font-weight-regular {
    font-weight: 400 !important;
  }
}

.v-btn:not(.v-btn--icon) {
  letter-spacing: normal !important;
  text-transform: none !important;

  &.standard-size {
    min-width: 160px !important;
    height: 40px !important;
  }
}

.v-main__wrap {
  display: flex;
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  padding: 20px;
  position: relative;
}

.form {
  width: 500px;
}

.link {
  cursor: pointer;
  transition: all 0.2s;
  color: var(--v-primary-base);

  &:hover {
    text-decoration: underline;
    color: var(--v-primary-lighten1);
  }
}

.sidebar {
  width: 200px;
  border-right: 1px solid #eeeeee;
  flex-shrink: 0;
}

.inner-wrapper {
  width: 700px;
}

.scrollable {
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: 0px;
}
</style>
