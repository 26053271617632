import axios from 'axios'

export default {
    state: {
        currentPage: 1,
        numOfPages: null,
        incidents: [],
        incidentsFetched: false,
        activeIncident: null,
        incidentsLoader: false,
        getIncidentsError: false
    },
    mutations: {
        getIncidents(state, incidents) {
            state.numOfPages = incidents.totalPageCount;
            state.incidents = incidents.results;
        },
        setCurrentPage(state, currentPage) {
            state.currentPage = currentPage;
        },
        setIncidentsFetched(state, incidentsFetched) {
            state.incidentsFetched = incidentsFetched;
        },
        setActiveIncident(state, incident) {
            state.activeIncident = state.incidents.find(i => i.emergencyId == incident);
        },
        toggleIncidentsLoader(state, incidentsLoader) {
            state.incidentsLoader = incidentsLoader
        },
        toggleIncidentsError(state, er) {
            state.getIncidentsError = er
        }
    },
    actions: {
        async getIncidents({ commit, state }, refresh = false) {
            if (state.incidentsFetched && !refresh) return;

            try {
                commit("toggleIncidentsError", false);
                commit("toggleIncidentsLoader", true);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/ta/emergency/list`, {
                    params: {
                        pageSize: 5,
                        pageNo: state.currentPage,
                    }
                });

                commit("toggleIncidentsLoader", false);
                commit('getIncidents', res.data);
                commit('setIncidentsFetched', true);

                if (state.activeIncident) {
                    commit("setActiveIncident", state.activeIncident.emergencyId);
                }
            } catch (e) {
                commit("toggleIncidentsLoader", false);
                commit("toggleIncidentsError", true);
                throw e
            }
        },
        async travellerResponse({ state, commit }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/travellerPolicy/approve`, {
                    insurancePolicyId: state.activeIncident.ownerTraveller.insurancePolicy.insurancePolicyId,
                    approvedByTa: payload.response,
                    changedBasedOnEmergencyId: state.activeIncident.emergencyId,
                    excessWaiverAmount: payload.excessWaiver,
                    hasExcessWaiver: !!payload.excessWaiver
                });
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })
                throw e;
            }
        },
        async incidentResponse({ state, commit }, taInsuranceAuthorized) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/ta/emergency/authorize`, {
                    emergencyId: state.activeIncident.emergencyId,
                    taInsuranceAuthorized
                })
            } catch (e) {
                commit("toggleSnackbar", {
                    open: true,
                    text: "Something went wrong. Please try again later.",
                    color: "error",
                    timeout: 5000
                })
                throw e;
            }
        }
    },
}