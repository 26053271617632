<template>
  <div class="component-wrapper pt-10">
    <form @submit.prevent="submit" novalidate>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            label="First Name"
            class="mb-1"
            v-model="firstName"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Last Name"
            class="mb-1"
            v-model="lastName"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Email"
            class="mb-1"
            v-model="email"
            disabled
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Mobile Number"
            class="mb-1"
            v-model="mobile"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="VAT/TAX registration Number"
            class="mb-1"
            v-model="vat"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="DUNS Number"
            class="mb-1"
            v-model="duns"
          >
          </v-text-field>
        </v-col>

        <v-col cols="4">
          <v-autocomplete
            outlined
            dense
            label="Country"
            class="mb-1"
            v-model="country"
            :items="countries"
            item-text="option_value"
            item-value="option_key"
            :loading="countriesLoader"
          ></v-autocomplete>

          <v-text-field
            outlined
            dense
            label="Address"
            class="mb-1"
            v-model="address"
          >
          </v-text-field>

          <v-text-field outlined dense label="City" class="mb-1" v-model="city">
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Postcode"
            class="mb-1"
            v-model="postCode"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Phone number"
            class="mb-1"
            v-model="phoneNumber"
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            outlined
            dense
            label="Registered name of assistance co."
            class="mb-1"
            v-model="assistanceCo"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Website"
            class="mb-1"
            v-model="website"
          >
          </v-text-field>

          <v-text-field
            outlined
            dense
            label="Fax Number"
            class="mb-1"
            v-model="fax"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-btn
        color="primary"
        :disabled="!hasChanges"
        type="submit"
        :loading="loader"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </form>

    <DiscardDialog :leavePath="leavePath" :initialize="initialize" />
  </div>
</template>

<script>
import DiscardDialog from "@/components/DiscardDialog";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    DiscardDialog,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      vat: "",
      duns: "",
      country: "",
      address: "",
      city: "",
      postCode: "",
      phoneNumber: "",
      assistanceCo: "",
      website: "",
      fax: "",
      initialData: null,
      leavePath: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
      countries: (state) => state.base.countries,
      countriesLoader: (state) => state.base.countriesLoader,
    }),
    hasChanges() {
      return (
        JSON.stringify(this.initialData) !==
        JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          mobile: this.mobile,
          vat: this.vat,
          duns: this.duns,
          country: this.country,
          address: this.address,
          city: this.city,
          postCode: this.postCode,
          phoneNumber: this.phoneNumber,
          assistanceCo: this.assistanceCo,
          website: this.website,
          fax: this.fax,
        })
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getCountries", "saveGeneralInfo"]),
    ...mapMutations(["toggleDiscardDialog"]),
    initialize() {
      this.firstName = this.profile.userProfile.firstName;
      this.lastName = this.profile.userProfile.lastName;
      this.email = this.profile.userProfile.email;
      this.mobile = this.profile.userProfile.mobile;
      this.vat = this.profile.companyProfile.taxId;
      this.duns = this.profile.companyProfile.DunsNo;
      this.country = this.profile.userProfile.homeCountryIso;
      this.address = this.profile.companyProfile.addressprofile.address;
      this.city = this.profile.companyProfile.addressprofile.city;
      this.postCode = this.profile.companyProfile.addressprofile.zipcode;
      this.phoneNumber = this.profile.companyProfile.phoneNo;
      this.assistanceCo = this.profile.companyProfile.registeredName;
      this.website = this.profile.companyProfile.website;
      this.fax = this.profile.companyProfile.faxNo;

      this.initialData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        mobile: this.mobile,
        vat: this.vat,
        duns: this.duns,
        country: this.country,
        address: this.address,
        city: this.city,
        postCode: this.postCode,
        phoneNumber: this.phoneNumber,
        assistanceCo: this.assistanceCo,
        website: this.website,
        fax: this.fax,
      };
    },
    async submit() {
      this.loader = true;

      try {
        await this.saveGeneralInfo({
          userProfile: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            mobile: this.mobile,
            homeCountryIso: this.country,
          },
          companyProfile: {
            taxId: this.vat,
            DunsNo: this.duns,
            phoneNo: this.phoneNumber,
            registeredName: this.assistanceCo,
            website: this.website,
            faxNo: this.fax,
            addressprofile: {
              address: this.address,
              city: this.city,
              zipcode: this.postCode,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasChanges) {
      next();
    } else {
      this.leavePath = to.path;
      this.toggleDiscardDialog(true);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
