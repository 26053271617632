<template>
  <div class="component-wrapper" :style="'width: 50%'">
    <div class="text-h6 mb-2">Card Information</div>

    <div
      class="d-flex align-center"
      style="width: 280px"
      v-if="profile.companyProfile && profile.companyProfile.lastCardDigits"
    >
      <div class="d-flex align-center credit-card py-1 px-3 flex-grow-1 mr-4">
        <svg v-if="profile.companyProfile.creditCardType == 'visa'">
          <use xlink:href="@/assets/sprite.svg#icon-visa" />
        </svg>

        <svg v-if="profile.companyProfile.creditCardType == 'mastercard'">
          <use xlink:href="@/assets/sprite.svg#icon-mastercard" />
        </svg>

        <div
          class="body-2 font-weight-medium ml-5"
          v-if="profile.companyProfile && profile.companyProfile.lastCardDigits"
        >
          xxxx-xxxx-xxxx-{{ profile.companyProfile.lastCardDigits }}
        </div>
      </div>

      <!-- <v-btn icon :ripple="false" color="error" @click="deleteDialog = true">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn> -->
    </div>

    <v-progress-circular
      indeterminate
      color="primary"
      size="50"
      width="4"
      class="mb-auto mx-auto mt-12"
      v-if="loader"
    ></v-progress-circular>

    <form v-if="submitted" id="payment-form" class="mt-4 mb-6">
      <div class="card-input" ref="payment_element"></div>
    </form>

    <v-btn
      v-if="
        profile.companyProfile &&
        !profile.companyProfile.lastCardDigits &&
        !loader
      "
      class="mb-2"
      color="primary"
      type="submit"
      @click="submit"
      :loading="loading"
    >
      <v-icon left v-if="$route.path.split('/')[1] != 'billing-info-register'"
        >mdi-content-save</v-icon
      >
      {{
        $route.path.split("/")[1] != "billing-info-register" ? "Save" : "Finish"
      }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
const stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PK}`);

export default {
  async mounted() {
    if (!this.profile.companyProfile?.lastCardDigits) {
      try {
        this.loader = true;
        await this.getSecretClient();

        const options = {
          clientSecret: `${this.stripeClientSecret}`,
        };

        this.elements = stripe.elements(options);

        this.card = this.elements.create("payment");

        this.card.mount(this.$refs.payment_element);

        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    }
  },

  data() {
    return {
      loader: false,
      submitted: true,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
      stripeClientSecret: (state) => state.profile.stripeClientSecret,
    }),
  },

  methods: {
    ...mapActions(["getSecretClient", "addPaymentInfo", "getProfile"]),

    async submit() {
      try {
        this.loading = true;
        const res = await stripe.confirmSetup({
          elements: this.elements,
          redirect: "if_required",

          confirmParams: {
            return_url: `https://dn2me-success-page.web.app/`,
          },
        });

        await this.addPaymentInfo({
          fromEmergency: false,
          setupIntent: res.setupIntent,
        });
        this.loading = false;
        this.submitted = false;

        if (this.$route.path.split("/")[1] == "billing-info-register") {
          this.getProfile(true);
          this.$router.push("/incidents");
        }
      } catch (e) {
        this.loading = false;

        this.$store.commit("toggleSnackbar", {
          open: true,
          color: "error",
          timeout: 5000,
          text: "Something went wrong. Please try again later.",
        });

        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card {
  border: 2px solid #e0e0e0;

  svg {
    height: 40px;
    width: 40px;
  }
}
</style>