<template>
  <div class="component-wrapper d-flex flex-column justify-start align-center">
    <form
      class="form elevation-3 d-flex flex-column pa-8 pt-5 rounded"
      @submit.prevent="submit"
      novalidate
    >
      <div class="text-h6 text-center mb-2">Forgot password?</div>

      <div class="body-1 mb-5 text-center">
        Don’t worry. Enter your email and we will send you instructions for
        creating a new password.
      </div>

      <v-text-field
        outlined
        label="Email"
        type="email"
        prepend-inner-icon="mdi-account"
        v-model="email"
        :error-messages="wrongCredentials ? 'Email not found' : ''"
      ></v-text-field>

      <v-btn
        color="primary"
        type="submit"
        :loading="loader"
        :disabled="!email"
        class="mb-5"
        >Request Reset Link</v-btn
      >

      <div
        class="caption text-center link"
        @click="$router.push({ path: '/login' })"
      >
        Back to Login
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      loader: false,
      wrongCredentials: false,
    };
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    async submit() {
      this.wrongCredentials = false;
      this.loader = true;

      try {
        await this.forgotPassword(this.email);

        this.loader = false;
      } catch (e) {
        if (e.response.data.errorCode == 10403) {
          this.wrongCredentials = true;
        }

        this.loader = false;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 50px;
}
</style>