<template>
  <div>
    <!-- <div class="text-h6 mb-6">General Information</div> -->

    <div class="d-flex">
      <div class="part mr-6">
        <!-- ====== PERSONAL INFO ====== -->
        <div class="subtitle-2 font-weight-medium mb-2">Personal Info</div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">First Name:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.ownerTraveller.userProfile.firstName }}
          </div>
        </div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Last Name:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.ownerTraveller.userProfile.lastName }}
          </div>
        </div>

        <div class="d-flex mb-6">
          <div class="body-1 mr-auto">Mobile Phone:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.ownerTraveller.userProfile.mobile }}
          </div>
        </div>

        <!-- ====== INSURANCE ====== -->
        <div class="subtitle-2 font-weight-medium mb-2">Insurance</div>
        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Policy No:</div>
          <div
            class="body-1 font-weight-medium"
            v-if="
              activeIncident.ownerTraveller.insurancePolicy &&
              activeIncident.ownerTraveller.insurancePolicy.insurancePolicyNum
            "
          >
            {{
              activeIncident.ownerTraveller.insurancePolicy.insurancePolicyNum
            }}
          </div>
        </div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Assistance ref. No:</div>
          <div
            class="body-1 font-weight-medium"
            v-if="
              activeIncident.ownerTraveller.insurancePolicy &&
              activeIncident.ownerTraveller.insurancePolicy.assistantRefNum
            "
          >
            {{ activeIncident.ownerTraveller.insurancePolicy.assistantRefNum }}
          </div>
        </div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Country of Destination:</div>
          <div
            class="body-1 font-weight-medium"
            v-if="
              activeIncident.ownerTraveller.insurancePolicy &&
              activeIncident.ownerTraveller.insurancePolicy.destCountryName
            "
          >
            {{ activeIncident.ownerTraveller.insurancePolicy.destCountryName }}
          </div>
        </div>

        <div class="d-flex mb-6">
          <div class="body-1 mr-auto">Travel dates:</div>
          <div
            class="body-1 font-weight-medium text-end"
            v-if="
              activeIncident.ownerTraveller.insurancePolicy &&
              activeIncident.ownerTraveller.insurancePolicy.policyStartDate &&
              activeIncident.ownerTraveller.insurancePolicy.policyEndDate
            "
          >
            {{
              moment
                .utc(
                  activeIncident.ownerTraveller.insurancePolicy.policyStartDate
                )
                .format("DD/MM/YYYY")
            }}
            -
            {{
              moment
                .utc(
                  activeIncident.ownerTraveller.insurancePolicy.policyEndDate
                )
                .format("DD/MM/YYYY")
            }}
          </div>
        </div>

        <div class="subtitle-2 font-weight-medium mb-2">Doctor Info</div>
        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">First Name:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.selectedDoctor.firstName }}
          </div>
        </div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Last Name:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.selectedDoctor.lastName }}
          </div>
        </div>

        <div class="d-flex mb-1">
          <div class="body-1 mr-auto">Speciality:</div>
          <div class="body-1 font-weight-medium">
            {{ activeIncident.selectedDoctor.doctorTypeNameEn }}
          </div>
        </div>
      </div>

      <div class="part map d-flex justify-end">
        <GmapMap
          :center="{
            lat: activeIncident.emergencyLocation.lat,
            lng: activeIncident.emergencyLocation.lon,
          }"
          :zoom="zoom"
          :options="options"
        >
          <GmapMarker
            :position="{
              lat: activeIncident.emergencyLocation.lat,
              lng: activeIncident.emergencyLocation.lon,
            }"
            :draggable="false"
            :icon="{ url: require('@/assets/red-pin.svg') }"
          />
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      zoom: 13,
      options: {
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
      },
      moment,
    };
  },
  computed: {
    ...mapState({
      activeIncident: (state) => state.ta.activeIncident,
    }),
  },
};
</script>

<style lang="scss" scoped>
.part {
  flex: 1;

  &.map {
    flex: 2;
  }
}

.vue-map-container {
  height: 300px;
  width: 500px;
}
</style>