import axios from 'axios'

export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null,
            timeout: null
        },
        countries: [],
        countriesLoader: false,
        discardDialog: false,
        nextStep: 'billing'
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },

        setNextStep(state, nextStep) {
            state.nextStep = nextStep
        },
        getCountries(state, countries) {
            state.countries = countries;
        },
        toggleCountriesLoader(state, countriesLoader) {
            state.countriesLoader = countriesLoader;
        },
        toggleDiscardDialog(state, dialog) {
            state.discardDialog = dialog;
        }
    },
    actions: {
        async getCountries({ commit, state }, refresh = false) {
            if (state.countries.length && !refresh) return;

            commit("toggleCountriesLoader", true);

            try {
                commit("getCountries", []);

                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/optionsList/countries/en`);

                commit("getCountries", res.data);
                commit("toggleCountriesLoader", false);
            } catch (e) {
                commit("toggleCountriesLoader", false);
                throw e;
            }
        }
    },
}
