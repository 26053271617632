<template>
  <div class="component-wrapper d-flex">
    <div class="incidents-sidebar grey lighten-3">
      <IncidentsSidebar />
    </div>

    <div class="incidents-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import IncidentsSidebar from "@/views/incidents/IncidentsSidebar";

export default {
  components: {
    IncidentsSidebar,
  },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
  padding: 0;
}

.incidents-sidebar {
  width: 400px;
  display: flex;
}

.incidents-content {
  flex: 1;
  display: flex;
}
</style>